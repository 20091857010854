import React from "react"
// import Header from "../components/header"
// import Footer from "../components/footer"
import user from "../../user.json"
// import "./Testing-styles/mainteam.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

function Teams() {
  const quest = (ans, index) => {
    return (
      <div className="col-lg-4 col-sm-12 mb-4" key={index}>
        <div
          className={"card-teams card h-100 m-2 bg-blue "}
          style={{ width: "100%" }}
        >
          <img
            src={ans.imgsrc}
            className="team"
            alt={ans.imgalt}
            style={{ height: "40vh", marginTop: "0.5rem" }}
          />
          <div className="card-body card-team-body d-flex flex-column justify-content-between">
            <div className="mx-3">
              <h5 className="card-title mb-2 mt-2">
                <a href={`/teams/${ans.url}`} style={{ color: "#FFFFFFAD" }}>
                  {ans.name}
                </a>
              </h5>
              <p className="card-text">{ans.expertise}</p>
              {/* <hr aria-hidden="true" className="border-primary" /> */}
              <ul className="card-lists list-unstyled m-0">
                {ans.experience_years && (
                  <li className="mb-1">
                    <i className="bx bxs-star" aria-hidden="true"></i>{" "}
                    {ans.experience_years} years
                  </li>
                )}
                {ans.phone_number && (
                  <li className="mb-1">
                    <i className="bx bxs-phone" aria-hidden="true"></i>
                    <a
                      className="px-1 contact"
                      href={`tel:${ans.phone_number}`}
                    >
                      {ans.phone_number}
                    </a>
                  </li>
                )}
                {ans.email && (ans.email.endsWith("@d2itechnology.com") || ans.email.endsWith("@d2itechnology.in")) && (
                  <li className="mb-1 d-flex">
                    <i className="bx bxs-envelope" aria-hidden="true"></i>
                    <a
                      className="px-2 contact text-break"
                      href={`mailto:${ans.email}`}
                    >
                      {ans.email}
                    </a>
                  </li>
                )}
                {ans.linkedin_url && (
                  <li className="mb-1 d-flex">
                    <i className="bx bxl-linkedin" aria-hidden="true"></i>
                    <a
                      className="px-2 contact text-break"
                      href={ans.linkedin_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {ans.linkedin_url}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <a
              href={`/teams/${ans.url}`}
              className={"btn btn-team btn-primary m-3"}
            >
              View Profile
            </a>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <Layout>
        {/* <Header /> */}
        <SEO
          title="Manual Testing Company in Delhi NCR, India, USA & UK"
          description="Manual Testing is one of the core services offered by D2i. D2i is one of the leading company in India specializing in Manual Testing."
        />
        <div className="container p-4">
          <div className="row justify-content-center">
            {user.user.map((ans, index) => (
              <React.Fragment key={index}>{quest(ans, index)}</React.Fragment>
            ))}
          </div>
        </div>
        {/* <Footer /> */}
      </Layout>
    </>
  )
}

export default Teams
